import React from 'react';
import Link from '/src/components/LocalizedLink';

import Layout from '../components/Layout';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

import '../assets/styles/pages/terms-and-privacy-page.scss';
import { URL_ROUTES_EN } from '../constants/routes';

const ApplyThankYou = () => {
  return (
    <Layout
      title="Application received | TEFL Iberia"
      description="Many thanks for your application!"
    >
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
              Application received
            </h1>
            <div className="subsection">
              <p className="subsection__par">
                Thank you for booking your course at Iberia Language Academy! We have received your
                payment and will contact you shortly to confirm your arrival date and course start
                time.
              </p>
              <p className="subsection__par">
                If you have any questions in the meantime please contact{' '}
                <a className="red-link" target="_blank" href="mailto:classes@ilabarcelona.com">
                  classes@ilabarcelona.com
                </a>
              </p>
              {/* <p className="subsection__par">
                We will also keep your details on file and contact you if future teaching positions
                become available.
              </p> */}
              <Link to={URL_ROUTES_EN.HOMEPAGE} className="c-btn c-btn--red mt-50">
                Return to the home page
                <IconArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyThankYou;
